/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { format } from 'date-fns-tz';
import { subHours } from 'date-fns';

import { Button } from '../index';
import pdf from '../../../files/Prem-Digital-Guest-Passes.pdf';
import shippingPdf from '../../../files/Suite-Shipping-Request-Form.pdf';

import styles from './BoxRowItem.scss';
import { config } from '../../../../config';

const images = require.context('../../../images/team-logos', true);

function GamesRowItems(props) {
  const { item, active, setActive } = props;
  const homeGame = item.venue === 'MINNEAPOLIS, MINN.';
  const [open, setOpen] = useState(false);
  const openToggle = open ? styles.open : '';
  const homeGameHeight = homeGame ? styles.home__game : '';
  const teamColorStyle = {
    color: item.againstColor,
  };
  useEffect(() => {
    active === item.id ? setOpen(true) : setOpen(false);
  }, [active]);

  return (
    <>
      <a
        className={cx(styles.box__row__header, openToggle)}
        role="button"
        tabIndex={item.id}
        onClick={() => {
          setActive(item.id);
          open ? setOpen(false) : setOpen(true);
        }}
      >
        <div className={cx(styles.box__row__header__item, styles.item__vs)}>
          <div>
            <img src={images(`./team_vikings.png`)} alt={item.againstImg} />
            <div>
              <span>minnesota</span> vikings
            </div>
          </div>
          <div>
            <span>{homeGame ? 'VS' : 'AT'}</span>
          </div>
          <div style={teamColorStyle}>
            <img src={`${config.BASE_URL}/${item.againstImg}`} alt="" />
            <div>
              <span>{item.againstCity}</span> {item.againstName}
            </div>
          </div>
        </div>
        <div className={cx(styles.box__row__header__item, styles.item__date)}>
          <div>
            {item.isTbd
              ? item.date
              : `${format(new Date(item.date), 'MMM')}. ${format(
                  new Date(item.date),
                  'd',
                )}`}
          </div>
        </div>
        <div className={cx(styles.box__row__header__item, styles.item__time)}>
          <div timezone={item.timezone}>
            {item.isTbd ? item.date : `${format(new Date(item.date), 'p')} CT`}
          </div>
        </div>
        <div className={cx(styles.box__row__header__item, styles.item__more)}>
          <div>More Info</div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31"
          height="31"
          className={styles.close}
        >
          <g>
            <path d="M.887 27.678L27.677.887l2.436 2.435-26.79 26.791z" />
            <path d="M3.322.887l26.791 26.79-2.435 2.436L.887 3.323z" />
          </g>
        </svg>
      </a>
      <div className={cx(styles.box__row__body, openToggle, homeGameHeight)}>
        <div className={cx(styles.box__row__body__item, styles.item__info)}>
          <div>
            <div>
              {item.isTbd
                ? item.date
                : `${format(new Date(item.date), 'EEEE')}, 
                ${format(new Date(item.date), 'MMM')}. ${format(
                    new Date(item.date),
                    'd',
                  )}`}
            </div>
            <div>{item.venue}</div>
          </div>
          <div>
            <div timezone={item.timezone}>
              {item.isTbd
                ? item.date
                : `${format(new Date(item.date), 'p')} CT`}{' '}
              <span>Kick off</span>
            </div>
            <div
              timezone={item.gatesTimezone}
              // timezone={
              //   item.gatesTime === '10:00'
              //     ? `AM ${
              //         // eslint-disable-next-line no-nested-ternary
              //         item.timezone === 'PM SCT'
              //           ? 'SCT'
              //           : item.timezone === 'PM CST'
              //           ? 'CST'
              //           : 'CDT'
              //       }`
              //     : item.timezone
              // }
            >
              {item.isTbd
                ? item.date
                : `${format(subHours(new Date(item.date), 2), 'p')} CT`}{' '}
              <span>gates</span>
            </div>
          </div>
        </div>
        <div className={cx(styles.box__row__body__item, styles.item__event)}>
          {/* {homeGame && (
            <>
              <h5>game feature event</h5>
              <h3>{item.featureEvent}</h3>
              <p>{item.featureEventDesc}</p>
            </>
          )} */}
        </div>
        {homeGame && (
          <>
            <div
              className={cx(styles.box__row__body__item, styles.item__tickets)}
            >
              {/* <Button
                text="Request SRO Tickets"
                btnType="purple"
                icon="ticket"
                href="https://minnesotavikings.formstack.com/forms/request_sro_tickets"
                externalLink
              /> */}
              {/* <Button
                text="Request Guest Passes"
                btnType="purple"
                icon="ticket"
                href={pdf}
                externalLink
              /> */}
              {/* <Button
                text="Request Guest Passes"
                btnType="purple"
                icon="ticket"
                href="https://minnesotavikings.formstack.com/forms/request_guest_passes"
                externalLink
              /> */}
              {/* <Button
                text="tell us about your suite"
                btnType="purple"
                icon="chat"
                href="https://minnesotavikings.formstack.com/forms/tell_us_about_your_suite"
                externalLink
              /> */}
              <Button
                text="Manage My Tickets"
                btnType="purple"
                href="https://am.ticketmaster.com/vikings/"
                icon="person"
                externalLink
              />
              <Button
                text="Suite Catering"
                btnType="purple"
                icon="catering"
                href="https://www.suitecatering.com/login/"
                externalLink
              />
              <Button
                text="Northern Lights – Omni Viking Lakes Hotel"
                btnType="purple"
                btnSize="long"
                icon="hotel"
                href="https://www.vikings.com/fans/viking-lakes/northern-lights-package"
                externalLink
              />
              <Button
                text="Donate Your Suite"
                btnType="purple"
                btnSize="long"
                href="https://minnesotavikings.formstack.com/forms/suite_donation_request"
                externalLink
              />
              <Button
                text="Shipping Items"
                btnType="purple"
                btnSize="long"
                href={shippingPdf}
                externalLink
              />
              <Button
                text="Purchase Gameday Gift Packs"
                btnType="purple"
                btnSize="long"
                href="https://www.vikings.com/stadium/celebration-packs"
                externalLink
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default GamesRowItems;
